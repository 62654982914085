import { AppService } from "./app-service";
import {
  Nota,
  MetaNota,
  ContainerNota,
  ContainerNotaVdpResponse,
} from "./../entities/NotaCertificacao";
import { ApiResponse } from "./../entities/api-response";
import { Observable, of } from "rxjs";
import { delay, map } from "rxjs/operators";
import { NotaCertificacao } from "src/app/entities/NotaCertificacao";

import { Injectable } from "@angular/core";

import { EtapaABR } from "../entities/etapas";
import { HistoricoCertificacao } from "../entities/historicoCertificacao";
import { createStatus, Status } from "../entities/status";
import { UnidadeProdutiva } from "../entities/unidade-produtiva";
import { Services } from "./service";
import { Safra } from "../entities/safra";
import { IFormularioService } from "./IFormulatio";
import { NotaService } from "./nota.service";

@Injectable({
  providedIn: "root",
})
export class VcpService implements IFormularioService {
  constructor(
    private service: Services,
    private appService: AppService,
    private notaService: NotaService
  ) {}

  getStatus(
    unidadeProdutiva: UnidadeProdutiva,
    safra: Safra
  ): Observable<Status> {
    return this.appService.formatarStatus(
      this.service.get(`EtapaVcp/${unidadeProdutiva.id}/${safra.id}/status`)
    );
  }

  getStatusSafraSelecionada(
    unidadadeProdutiva: UnidadeProdutiva
  ): Observable<Status> {
    const safra = this.appService.getSafraSelecionada();
    return this.getStatus(unidadadeProdutiva, safra);
  }

  getStatusAtual(
    unidadeProdutiva: UnidadeProdutiva,
    safra: Safra
  ): Observable<Status> {
    return this.appService.formatarStatus(
      this.service.get(
        `EtapaVcp/${unidadeProdutiva.id}/${safra.id}/statusEtapaAtual`
      )
    );
  }

  getStatusAtualSafraSelecionada(
    unidadadeProdutiva: UnidadeProdutiva
  ): Observable<Status> {
    const safra = this.appService.getSafraSelecionada();
    return this.getStatusAtual(unidadadeProdutiva, safra);
  }

  concluirVcpUnidadeProdutivaReprovada(
    unidadeProdutiva: UnidadeProdutiva,
    isAbr: number
  ) {
    return this.service.patch(
      `EtapaVcp/${unidadeProdutiva.id}/${isAbr}/ConcluirVcpReprovada`
    );
  }

  concluirVcpFinalizarProcesso(unidadeProdutivaId: number) {
    return this.service.patch(`EtapaVcp/${unidadeProdutivaId}/ConcluirVcp`);
  }

  buscarFormulario(unidadeProdutiva: UnidadeProdutiva) {
    const safra = this.appService.getSafraSelecionada();
    return this.service.patch(
      `EtapaVcp/${unidadeProdutiva.id}/${safra.id}/buscarFormulario`,
      {}
    );
  }

  reenviarEmail(
    unidadeProdutiva: UnidadeProdutiva
  ): Observable<{ message: string }> {
    return this.service.post(
      `EtapaVcp/${unidadeProdutiva.id}/reenviaEmailVcp`,
      {}
    );
  }

  getNotaNecessariaSafra(codigoSafra: number): Observable<NotaCertificacao> {
    return of({
      percentual: 91,
    } as NotaCertificacao);
    // return this.service.getBy("EtapaVcp/obterNotaNecessaria", codigoSafra);
  }

  getNotaObtida(unidadeProdutiva: UnidadeProdutiva): Observable<number> {
    return of(89);
    // return this.service.getBy(
    //   "EtapaVcp/BuscarPercentualAtingido",
    //   unidadeProdutiva?.idProdutorGrupo
    // );
  }

  /**
   * Obtém as notas de uma unidade produtiva na safra selecionada globalmente.
   *
   * @param unidadadeProdutivaId
   */
  getNotas(
    unidadadeProdutiva: UnidadeProdutiva,
    safra: Safra
  ): Observable<ContainerNota> {
    return this.notaService.formatarNotas(
      this.service.get(
        `EtapaVcp/${unidadadeProdutiva.id}/${safra.id}/buscarNotaCertificacaoPorSafraPorUp`
      )
    );
  }

  getNotasPorEtapa(
    unidadadeProdutiva: UnidadeProdutiva,
    safra: Safra
  ): Observable<any> {
    return this.notaService.formatarNotasVCP(
      this.service.get(
        `EtapaVcp/${unidadadeProdutiva.id}/${safra.id}/buscarNotaCertificacaoPorSafraPorUp`
      )
    );
  }

  getNotasSafraSelecionada(
    unidadeProdutiva: UnidadeProdutiva
  ): Observable<ContainerNota> {
    const safra = this.appService.getSafraSelecionada();
    return this.getNotas(unidadeProdutiva, safra);
  }

  getNotasSafraSelecionadaEtapaVcp(
    unidadeProdutiva: UnidadeProdutiva
  ): Observable<any> {
    const safra = this.appService.getSafraSelecionada();
    return this.getNotasPorEtapa(unidadeProdutiva, safra);
  }

  vcpAprovado(unidadeProdutiva: UnidadeProdutiva): boolean {
    return true;
  }
}
