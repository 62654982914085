<div class="modal-vcp-container">
  <div class="header-content">
    <button
      (click)="selecionarModo('resumo-bci')"
      class="btn btn-light btn-md"
      [ngClass]="{ 'active-btn': modoSelecionado === 'resumo-bci' }"
    >
      Resumo VCP/BCI
    </button>
    <button
      (click)="selecionarModo('resumo-abr')"
      class="btn btn-light btn-md"
      [ngClass]="{ 'active-btn': modoSelecionado === 'resumo-abr' }"
    >
      Resumo VCP/ABR
    </button>
  </div>

  <div *ngIf="modoSelecionado === 'resumo-bci'" class="px-2 w-100">
    <app-modal-notas
      title="Resumo da VCP/BCI (%)"
      [unidadeProdutiva]="unidadeProdutiva"
      [containerNota]="containerNota.notasVcpBci"
      [modal]="modal"
    ></app-modal-notas>
  </div>
  <div *ngIf="modoSelecionado === 'resumo-abr'" class="px-2 w-100">
    <app-modal-notas
      title="Resumo da VCP/ABR (%)"
      [unidadeProdutiva]="unidadeProdutiva"
      [containerNota]="containerNota.notasVcpAbr"
      [modal]="modal"
    ></app-modal-notas>
  </div>

  <div class="modal-footer footer-content-responsive mt-4">
    <button type="button" class="btn btn-light" (click)="onClose()">
      Fechar
    </button>
  </div>
</div>
