import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";

import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { EtapasEnum } from "../etapas-enum";
import { InformacaoEnum } from "./informacao-enum";
import { SolicitarRetornoEtapaService } from "src/app/services/solicitar-retono-etapa.service";
import { ToastService } from "src/app/services/toast.service";
import { ToastType } from "src/app/entities/toast";
import { SpinnerService } from "src/app/services/spinner.service";
import { AlertModalService } from "src/app/services/alert-modal.service";
import { map } from "rxjs/operators";
import { ConfirmacaoRetornoEnum } from "./confirmacao-retorno-enum";
import { BasicModalComponent } from "src/app/shared/basic-modal/basic-modal.component";
import { BasicModalConfig } from "src/app/shared/basic-modal/basic-modal.config";

@Component({
  selector: "modal-solicitar-retorno",
  templateUrl: "./modal-solicitar-retorno.component.html",
  styleUrls: ["./modal-solicitar-retorno.component.scss"],
})
export class ModalSolicitarRetornoComponent implements OnInit {
  @Input() etapa: string;
  @Input() etapaAtual: any;
  @Input() habilitarSolicitacao: boolean;
  @Output() recarregarSolicitacao = new EventEmitter();
  spinnerVisibility: boolean = false;
  informacao: string = "";
  etapaDesejada: string = "";
  iconInfo = faInfoCircle;
  modalRef: NgbModalRef;

  @ViewChild("modalConfirmSolicitarRetorno")
  private modalConfirmSolicitarRetorno: BasicModalComponent;

  public modalConfirmSolicitarRetornoConfig: BasicModalConfig = {
    modalTitle: `Atenção`,
    hideDenyButton: true,
    hideSaveButton: false,
    hideCloseButton: false,
    colorCloseButton: "secondary",
    saveButtonLabel: "Sim",
    options: {
      centered: false,
      size: "md",
    },
    onClose: async () => {
      return;
    },
    onSave: async () => {
      this.submit();
    },
  };

  textBodyModal: string = "";

  formSolicitacaoRetorno = this.fb.group({
    motivo: ["", Validators.required],
    idStatusCertificacaoAtual: [0, Validators.required],
    idEtapaCertificacaoDesejado: [0, Validators.required],
  });

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private toastService: ToastService,
    private solicitacaoServices: SolicitarRetornoEtapaService,
    private fb: FormBuilder,
    private spinnerService: SpinnerService,
    private alertService: AlertModalService
  ) {}

  ngOnInit(): void {}

  open(content) {
    this.modalRef = this.modalService.open(content, {
      size: "md",
      centered: true,
    });
    this.atribuirValores();
  }

  atribuirValores() {
    this.informacao = InformacaoEnum[this.etapa];
    this.formSolicitacaoRetorno.controls.idStatusCertificacaoAtual.setValue(
      this.etapaAtual.idStatusCertificacao
    );
    this.buscaEtapaDesejada();
  }

  buscaEtapaDesejada() {
    let idEtapaDesejada = 0;
    if (this.etapa === "VDP") {
      idEtapaDesejada = EtapasEnum["VDP - Em andamento"];
    }

    if (this.etapa === "VCP") {
      idEtapaDesejada = EtapasEnum["VCP - Em andamento"];
    }
    if (this.etapa === "emissão do Certificado") {
      idEtapaDesejada = EtapasEnum["Certificado em emissão"];
    }

    if (this.etapa === "PCNC") {
      this.solicitacaoServices
        .verificarSeReprovouNoVDB(this.etapaAtual.idUpCertificacao)
        .subscribe(
          (data) => {
            if (data) {
              idEtapaDesejada = EtapasEnum["PCNC - Em andamento"];
            } else {
              idEtapaDesejada =
                EtapasEnum["PCNC - Ausente de Não Conformidades"];
            }
            this.formSolicitacaoRetorno.controls.idEtapaCertificacaoDesejado.setValue(
              idEtapaDesejada
            );
            this.etapaDesejada = EtapasEnum[idEtapaDesejada];
          },
          (err) => {
            this.toastService.showToast(ToastType.DANGER, "Error", err);
          }
        );
    }

    this.formSolicitacaoRetorno.controls.idEtapaCertificacaoDesejado.setValue(
      idEtapaDesejada
    );
    this.etapaDesejada = EtapasEnum[idEtapaDesejada];
  }

  openModalConfirmSolicitarRetorno() {
    this.textBodyModal = `${ConfirmacaoRetornoEnum[this.etapa]}`;
    this.modalConfirmSolicitarRetorno.open();
  }

  submit() {
    this.spinnerService.show();
    this.solicitacaoServices.post(this.formSolicitacaoRetorno.value).subscribe({
      next: (res) => {
        this.recarregarSolicitacao.emit();
      },
      complete: () => {
        this.closeModal();
      },
    });
  }

  closeModal() {
    this.modalRef.close();
    this.modalConfirmSolicitarRetorno.close();
    this.spinnerService.hide();
  }
}
