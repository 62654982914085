import { VcpService } from "src/app/services/vcp.service";

import { Component, Input, OnInit } from "@angular/core";

import { UnidadeProdutiva } from "../../../entities/unidade-produtiva";

@Component({
  selector: "app-modal-etapa-vcp-nota",
  templateUrl: "./modal-etapa-vcp-nota.component.html",
  styleUrls: ["./modal-etapa-vcp-nota.component.scss"],
})
export class ModalEtapaVcpNotaComponent implements OnInit {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() containerNota: any;
  @Input() modal: any;

  loadingNotas = true;

  modoSelecionado: string = "resumo-bci";

  constructor(public vcpService: VcpService) {}

  ngOnInit(): void {}

  selecionarModo(modo: string) {
    this.modoSelecionado = modo;
  }

  onClose() {
    this.selecionarModo("resumo-bci");
    this.modal.close();
  }
}
