<ng-container *ngIf="!loading; else shimmer">
  <button
    *ngIf="temPerfil('ESTADUAL') || temPerfil('RESPONSAVEL_ABR')"
    class="btn btn-sm btn-link pl-0"
    (click)="open(content)"
  >
    <fa-icon [icon]="icon" size="sm" class="mr-2"></fa-icon>
    Notas VDP
  </button>
</ng-container>
<ng-template #shimmer>
  <div *ngFor="let item of shimmers" class="d-flex flex-column mb-3">
    <shimmer width="80%" height="18px"></shimmer>
  </div>
</ng-template>

<ng-template #content let-modal>
  <modal-etapa-vdp-nota
    [containerNota]="containerNotaVdp"
    [unidadeProdutiva]="unidadeProdutiva"
    [modal]="modal"
  ></modal-etapa-vdp-nota>
</ng-template>
