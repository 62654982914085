<button
  class="btn btn-outline-primary btn-sm"
  (click)="open(content)"
  [disabled]="habilitarSolicitacao"
>
  Solicitar retorno para a Etapa {{ etapa }}
</button>

<ng-template #content let-modal>
  <spinner [visible]="spinnerVisibility"></spinner>
  <br />
  <div class="col lg-12 m-2">
    <h6 class="card-title">
      <strong> Solicitar Retorno para a {{ etapa }} </strong>
    </h6>
    <div class="alert alert-primary" role="alert">
      <fa-icon [icon]="iconInfo" size="sm"></fa-icon>&nbsp;
      {{ informacao }}
    </div>
    <div class="modal-body">
      <form [formGroup]="formSolicitacaoRetorno">
        <div class="row">
          <div class="col">
            <p class="text-muted">Etapa atual</p>
            <p>
              <strong>
                <app-etapa-abr-dashboard-td
                  [etapa]="etapaAtual.descricao"
                ></app-etapa-abr-dashboard-td>
              </strong>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="text-muted">Etapa desejada</p>
            <p>
              <strong>
                <app-etapa-abr-dashboard-td
                  [etapa]="etapaDesejada"
                ></app-etapa-abr-dashboard-td>
              </strong>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="text-muted">Motivo da solicitação*</p>
            <textarea
              formControlName="motivo"
              class="form-control"
              rows="3"
            ></textarea>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light btn-sm" (click)="closeModal()">
        Cancelar
      </button>
      <button
        type="button"
        class="btn btn-primary btn-sm"
        [disabled]="!formSolicitacaoRetorno.valid"
        (click)="openModalConfirmSolicitarRetorno()"
      >
        Enviar Solicitação
      </button>
    </div>
  </div>
</ng-template>

<basic-modal
  #modalConfirmSolicitarRetorno
  [modalConfig]="modalConfirmSolicitarRetornoConfig"
>
  <div>
    <p>{{ textBodyModal }}</p>
  </div>
</basic-modal>
