<div *ngIf="!habilitarAcessarVdp" class="alert alert-info" role="alert">
  <fa-icon [icon]="iconInfo" size="sm"></fa-icon>&nbsp;Não compartilhe o link de
  acesso à lista VDP. Pessoas com acesso a este link poderão preencher o
  formulário indevidamente.
</div>
<div class="row">
  <div class="col-lg-4 mb-2 mb-lg-0">
    <titulo-modal>Formulário VDP</titulo-modal>
    <div class="row button-content-responsive">
      <div class="col-lg-12 button-responsive">
        <button
          [disabled]="habilitarAcessarVdp || loadingButton"
          (click)="preencherVdp()"
          class="btn btn-sm btn-primary"
        >
          <fa-icon [icon]="icon" size="sm"></fa-icon>&nbsp;Acessar VDP
        </button>
      </div>
      <div class="col-lg-12 resume-button-responsive">
        <ativador-tooltip
          [isBtn]="true"
          label="Resumo da VDP (%)  "
          [tooltipEnabled]="false"
          tooltipText="O formulário ainda não foi enviado"
          (action)="open(content)"
        >
        </ativador-tooltip>
        <!-- <ng-container *ngIf="showSpanProcessSelectVcp; else empty">
          <span class="choice-span">
            {{ isEscolhidoFoiBci ? "Optante BCI" : "Não Optante BCI" }}</span
          >
        </ng-container>
        <ng-template #empty>
          <span class="choice-span"> --- </span>
        </ng-template> -->
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <app-status-convite
      [loading]="loadingStatus"
      title="Status"
      [status]="status"
    ></app-status-convite>
  </div>
  <div class="col-lg-2 options-content-responsive">
    <dropdown-btn labelAtivadora="Outras opções">
      <dropdown-btn-item
        *ngIf="safraVigenteAtivaStatusVDPEmAndamentoCompleto"
        (actionPerformed)="reenviarEmail()"
      >
        Reenviar e-mail VDP
      </dropdown-btn-item>
      <dropdown-btn-item
        *ngIf="podePularVdp"
        (actionPerformed)="openModalEtapaVdp('pular-etapa')"
      >
        Pular etapa <strong>VDP e PCNC</strong>
      </dropdown-btn-item>
      <dropdown-btn-item
        (actionPerformed)="open(nota)"
        *ngIf="exibirNotaExplicativa"
      >
        Acessar <strong>Nota Explicativa</strong>
      </dropdown-btn-item>
      <dropdown-btn-item *ngIf="podeSolicitarRetorno">
        <modal-retorno
          [etapa]="'VDP'"
          [etapaAtual]="etapaAtual"
          [unidadeProdutiva]="unidadeProdutiva"
        ></modal-retorno>
      </dropdown-btn-item>
    </dropdown-btn>
  </div>
</div>
<br />

<ng-template #content let-modal>
  <modal-etapa-vdp-nota
    [containerNota]="containerNota"
    [unidadeProdutiva]="unidadeProdutiva"
    [modal]="modal"
  ></modal-etapa-vdp-nota>
</ng-template>

<ng-template #nota let-modal>
  <app-modal-vdp-nota-explicativa
    (notaEnviada)="loading()"
    [modal]="modal"
    *ngIf="!notaExplicativa"
    [unidadeProdutiva]="unidadeProdutiva"
  >
  </app-modal-vdp-nota-explicativa>
  <app-modal-vdp-nota-explicativa-preenchida
    [modal]="modal"
    *ngIf="notaExplicativa"
    [unidadeProdutiva]="unidadeProdutiva"
  >
  </app-modal-vdp-nota-explicativa-preenchida>
</ng-template>

<basic-modal #modalEtapaVdp [modalConfig]="modalEtapaVdpConfig">
  <div>
    <p>{{ textBodyModal }}</p>
  </div>
</basic-modal>
