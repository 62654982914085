import {
  Component,
  Injectable,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { BasicModalConfig } from "./basic-modal.config";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "basic-modal",
  templateUrl: "./basic-modal.component.html",
  styleUrls: ["./basic-modal.component.scss"],
})
@Injectable()
export class BasicModalComponent implements OnInit {
  @Input() public modalConfig: BasicModalConfig;
  @Input() showDownloadButton: boolean = false;
  @ViewChild("modal") private modalContent: TemplateRef<BasicModalComponent>;
  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  open(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.modalRef = this.modalService.open(this.modalContent, {
        beforeDismiss: () => {
          this.close(this.modalConfig.closeModal);
          return true;
        },
        ...this.modalConfig?.options,
      });
      this.modalRef.result.then(resolve, resolve);
    });
  }

  async close(closeModal = true): Promise<void> {
    if (
      this.modalConfig.shouldClose === undefined ||
      (await this.modalConfig.shouldClose())
    ) {
      if (!closeModal) {
        await this.modalConfig.onClose();
      } else {
        const result =
          this.modalConfig.onClose === undefined ||
          (await this.modalConfig.onClose());
        this.modalRef?.close(result);
      }
    }
  }

  async deny(closeModal = true): Promise<void> {
    if (
      this.modalConfig.shouldDeny === undefined ||
      (await this.modalConfig.shouldDeny())
    ) {
      if (!closeModal) {
        await this.modalConfig.onDeny();
      } else {
        const result =
          this.modalConfig.onDeny === undefined ||
          (await this.modalConfig.onDeny());
        this.modalRef?.close(result);
      }
    }
  }

  async save(closeModal = true): Promise<void> {
    if (
      this.modalConfig.shouldSave === undefined ||
      (await this.modalConfig.shouldSave())
    ) {
      if (!closeModal) {
        await this.modalConfig.onSave();
      } else {
        const result =
          this.modalConfig.onSave === undefined ||
          (await this.modalConfig.onSave());
        this.modalRef?.close(result);
      }
    }
  }
}
