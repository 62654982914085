export interface NotaExplicativa {
  id: number;
  descricao: string;
}

export class ConfirmarNotaExplicativa {
  id: number;
  upId: number;
  upCertificacaoId: number;
  upSedeId: number;
  safraId: number;
  contratoTrabalho: boolean;
  proibicaoTrabalhoInfantil: boolean;
  proibicaoTrabalhoEscravo: boolean;
  liberdadeAssociacaoSindical: boolean;
  proibicaoDiscriminacaoPessoas: boolean;
  segurancaSaudeOcupacMeioAmbiTrabalho: boolean;
  desempenhoAmbiental: boolean;
  boasPraticasAgricolas: boolean;
  metasSustentabilidade: boolean;
}

export interface CheckListNotaExplicativa {
  id: string;
  descricao: string;
  value: boolean;
}
