<div
  *ngIf="podeAcessarVcp"
  class="alert alert-info text-align-responsive"
  role="alert"
>
  <fa-icon [icon]="iconInfo" size="sm"></fa-icon>&nbsp;Não compartilhe o link de
  acesso à lista VCP. Pessoas com acesso a este link poderão preencher o
  formulário indevidamente.
</div>
<div class="row">
  <div class="col-lg-3 button-content-responsive">
    <titulo-modal>Formulário VCP</titulo-modal>

    <app-external-link-btn [disabled]="!podeAcessarVcp" (click)="preencherVcp()"
      >Acessar VCP</app-external-link-btn
    >

    <div class="mt-3 resume-button-responsive">
      <ativador-tooltip
        [isBtn]="true"
        label="Resumo da VCP (%) "
        tooltipText="O formulário ainda não foi enviado"
        (action)="open(content)"
      >
      </ativador-tooltip>
      <!-- <ng-container *ngIf="showSpanProcessSelectVcp; else empty">
        <span class="choice-span">
          {{ isEscolhidoFoiBci ? "Optante BCI" : "Não Optante BCI" }}</span
        >
      </ng-container>
      <ng-template #empty>
        <span class="choice-span"> --- </span>
      </ng-template> -->
    </div>
  </div>
  <div class="col-lg-7">
    <app-status-convite title="Status" [status]="status"></app-status-convite>
  </div>
  <div
    class="col-lg-2 mb-4 mt-2 mt-lg-0 options-content-responsive"
    *ngIf="podeVerOutrasOpcoes"
  >
    <dropdown-btn labelAtivadora="Outras opções">
      <dropdown-btn-item
        (actionPerformed)="reenviarEmail()"
        *ngIf="podeReenviarEmail"
        >Reenviar e-mail VCP</dropdown-btn-item
      >
      <dropdown-btn-item
        (actionPerformed)="open(nota)"
        *ngIf="podeVerNotaExplicativa"
      >
        Acessar <strong>Nota Explicativa</strong>
      </dropdown-btn-item>
      <dropdown-btn-item *ngIf="podeSolicitarRetorno">
        <modal-retorno
          [etapa]="'VCP'"
          [etapaAtual]="etapaAtual"
          [unidadeProdutiva]="unidadeProdutiva"
        ></modal-retorno>
      </dropdown-btn-item>
    </dropdown-btn>
  </div>
</div>
<div class="row mt-4">
  <div class="col-12 actions-buttons-responsive">
    <button
      [disabled]="podeReprovarVcp"
      (click)="checkIfUserCanConcludeReprovedVcp()"
      class="col-lg-5 btn btn-sm btn-danger"
    >
      Encerrar processo com a Unidade Produtiva Reprovada
    </button>

    <button
      [disabled]="podeFinalizarVcp"
      (click)="onOpenModalSelectOptionVcp('finalizar-processo')"
      class="col-lg-5 btn btn-sm btn-success"
    >
      Encerrar processo com a Unidade Produtiva Aprovada
    </button>
  </div>
</div>

<ng-template #content let-modal>
  <app-modal-etapa-vcp-nota
    [modal]="modal"
    [containerNota]="containerNota"
    [unidadeProdutiva]="unidadeProdutiva"
  >
  </app-modal-etapa-vcp-nota>
</ng-template>
<ng-template #nota let-modal>
  <app-modal-vdp-nota-explicativa
    [modal]="modal"
    *ngIf="!notaExplicativa"
    [unidadeProdutiva]="unidadeProdutiva"
  >
  </app-modal-vdp-nota-explicativa>
  <app-modal-vdp-nota-explicativa-preenchida
    [modal]="modal"
    *ngIf="notaExplicativa"
    [unidadeProdutiva]="unidadeProdutiva"
  >
  </app-modal-vdp-nota-explicativa-preenchida>
</ng-template>

<basic-modal #modalConfirmProcesso [modalConfig]="modalConfirmProcessoConfig">
  <div>
    <p>{{ textBodyModal }}</p>
  </div>
</basic-modal>

<basic-modal #modalSelectOptionVcp [modalConfig]="modalSelectOptionVcpConfig">
  <form [formGroup]="vcpForm">
    <div class="modal-vcp-content">
      <div>
        <p>
          Para dar continuidade no processo, é necessário que escolha a VCP.
        </p>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="vcpSelecionado" class="col-form-label col-form-label-sm"
            >Selecione o VCP:</label
          >
          <select
            id="vcpSelecionado"
            class="form-control form-control-sm"
            formControlName="vcpSelecionado"
            placeholder="Selecione..."
          >
            <option *ngFor="let vcp of vcpList" [value]="vcp.id">
              {{ vcp.descricao }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </form>
</basic-modal>
