<a (click)="open(content)">
  Solicitar retorno para o <strong>{{ etapa }}</strong>
</a>
<ng-template #content let-modal>
  <spinner [visible]="spinnerVisibility"></spinner>
  <br />
  <div class="col lg-12 m-2">
    <h6 class="card-title">
      <strong>Retorno para a {{ etapa }}</strong>
    </h6>

    <modal-solicitar-retorno
      *ngIf="etapaAtual"
      [etapa]="etapa"
      [etapaAtual]="etapaAtual"
      [habilitarSolicitacao]="habilitarSolicitacao"
      (recarregarSolicitacao)="carregarSolicitacaoDeRetorno()"
    ></modal-solicitar-retorno>

    <div class="modal-body" *ngIf="solicitacao !== null">
      <div class="row">
        <div class="col-md-4">
          <p class="text-muted">Solicitante</p>
          <p style="text-transform: uppercase">
            <strong>
              {{ solicitacao?.usuarioSolicitacao }}
            </strong>
          </p>
        </div>
        <div class="col-md-4">
          <p class="text-muted">Data/Hora Solicitação</p>
          <p>
            {{ solicitacao?.dataSolicitacao | date : "dd/MM/yyyy hh:mm:ss" }}
          </p>
        </div>
        <div class="col-md-4">
          <div class="status-solicitacao">
            <p class="text-muted">Status</p>
            <p style="text-transform: uppercase">
              <strong>
                <span [ngClass]="dotColor"></span> {{ solicitacao?.status }}
              </strong>
            </p>
            <button
              (click)="openModalConfirmSolicitarRetorno()"
              class="btn btn-outline-danger btn-sm"
            >
              Cancelar Solicitação
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4" *ngIf="solicitacao?.usuarioParecer !== null">
          <p class="text-muted">Resp. Parecer</p>
          <p style="text-transform: uppercase">
            <strong>
              {{ solicitacao?.usuarioParecer }}
            </strong>
          </p>
        </div>
        <div class="col-md-4" *ngIf="solicitacao?.dataParecer !== null">
          <p class="text-muted">Data/Hora Parecer</p>
          <p>
            {{ solicitacao?.dataParecer | date : "dd/MM/yyyy hh:MM:ss" }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <p class="text-muted">Motivo da Solicitação</p>
          <p style="text-transform: uppercase">
            {{ solicitacao?.motivo }}
          </p>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light btn-sm"
        (click)="modal.close()"
      >
        Cancelar
      </button>
    </div>
  </div>
</ng-template>

<basic-modal #modalCancelRetorno [modalConfig]="modalCancelRetornoConfig">
  <div>
    <p>
      Você tem certeza que deseja cancelar esta solicitação de retorno para a
      Unidade Produtiva selecionada?
    </p>
  </div>
</basic-modal>
