import { Component, Input, OnInit } from "@angular/core";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ContainerNota } from "src/app/entities/NotaCertificacao";
import { ToastType } from "src/app/entities/toast";
import { UnidadeProdutiva } from "src/app/entities/unidade-produtiva";
import { NotaService } from "src/app/services/nota.service";
import { PermissionService } from "src/app/services/permission-service";
import { SubscriptionService } from "src/app/services/subscription.service";
import { ToastService } from "src/app/services/toast.service";
import { VdpService } from "src/app/services/vdp.service";

@Component({
  selector: "app-notas-vdp",
  templateUrl: "./notas-vdp.component.html",
  styleUrls: ["./notas-vdp.component.scss"],
})
export class NotasVdpComponent implements OnInit {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() loading: boolean;

  icon = faClipboard;

  containerNotaVdp: any;
  loadingNotaVdp = false;

  shimmerAmount = 1;
  shimmers = [];

  constructor(
    private modalService: NgbModal,
    private toastService: ToastService,
    private notaService: NotaService,
    private vdpService: VdpService,
    private subscriptionService: SubscriptionService,
    private permissionService: PermissionService
  ) {
    this.containerNotaVdp = this.notaService.iniciarContainerNotaEtapaVdp();
  }

  ngOnInit(): void {
    this._carregarStatusNotaVCP();
    this.initShimmers();
  }

  initShimmers() {
    this.shimmers = Array(this.shimmerAmount);
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }

  temPerfil(permissao: string) {
    const role = this.permissionService.getPermission();
    return role.some((x) => x == permissao);
  }

  private _carregarStatusNotaVCP() {
    this.loadingNotaVdp = true;
    const vdp$ = this.vdpService
      .getNotasSafraSelecionadaEtapaVdp(this.unidadeProdutiva)
      .subscribe({
        next: (res) => {
          console.log({ ressp: res });
          this.containerNotaVdp = res;
        },
        error: (err) => {
          this.toastService.showToast(
            ToastType.DANGER,
            "Nota VDP",
            "Erro ao carregar dados"
          );
        },
        complete: () => {
          this.loadingNotaVdp = false;
        },
      });
    this.subscriptionService.add(vdp$);
  }

  open(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }
}
