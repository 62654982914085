import { Component, Input, OnInit } from "@angular/core";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  ContainerNota,
  ContainerNotaVcpResponse,
} from "src/app/entities/NotaCertificacao";
import { ToastType } from "src/app/entities/toast";
import { UnidadeProdutiva } from "src/app/entities/unidade-produtiva";
import { NotaService } from "src/app/services/nota.service";
import { PermissionService } from "src/app/services/permission-service";
import { SubscriptionService } from "src/app/services/subscription.service";
import { ToastService } from "src/app/services/toast.service";
import { VcpService } from "src/app/services/vcp.service";

@Component({
  selector: "app-notas-vcp",
  templateUrl: "./notas-vcp.component.html",
  styleUrls: ["./notas-vcp.component.scss"],
})
export class NotasVcpComponent implements OnInit {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() loading: boolean;

  icon = faClipboard;

  containerNotaVcp: ContainerNotaVcpResponse;
  loadingNotaVcp = false;

  shimmerAmount = 1;
  shimmers = [];

  constructor(
    private modalService: NgbModal,
    private toastService: ToastService,
    private notaService: NotaService,
    private vcpService: VcpService,
    private subscriptionService: SubscriptionService,
    private permissionService: PermissionService
  ) {
    this.containerNotaVcp = this.notaService.iniciarContainerNotaEtapaVcp();
  }

  ngOnInit(): void {
    this._carregarStatusNotaVCP();
    this.initShimmers();
  }

  initShimmers() {
    this.shimmers = Array(this.shimmerAmount);
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }

  temPerfil(permissao: string) {
    const role = this.permissionService.getPermission();
    return role.some((x) => x == permissao);
  }

  private _carregarStatusNotaVCP() {
    this.loadingNotaVcp = true;
    const vcp$ = this.vcpService
      .getNotasSafraSelecionadaEtapaVcp(this.unidadeProdutiva)
      .subscribe({
        next: (res) => {
          this.containerNotaVcp = res;
        },
        error: (err) => {
          this.toastService.showToast(
            ToastType.DANGER,
            "Nota VCP",
            "Erro ao carregar dados"
          );
        },
        complete: () => {
          this.loadingNotaVcp = false;
        },
      });
    this.subscriptionService.add(vcp$);
  }

  open(content) {
    if (
      !this.containerNotaVcp?.temNotaBci ||
      !this.containerNotaVcp?.temNotaAbr
    ) {
      return this.toastService.showToast(
        ToastType.INFO,
        "Nota VCP",
        "Etapa VDP não iniciada"
      );
    }
    this.modalService.open(content, { centered: true, size: "xl" });
  }
}
