import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { finalize, switchMap } from "rxjs/operators";
import {
  ContainerNota,
  ContainerNotaVcpResponse,
  NotaCertificacao,
  StatusNota,
} from "src/app/entities/NotaCertificacao";
import { Status } from "src/app/entities/status";
import { UnidadeProdutiva } from "src/app/entities/unidade-produtiva";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

import { EtapaABR } from "../../entities/etapas";
import { Services } from "../../services/service";
import { SpinnerService } from "../../services/spinner.service";
import { ToastService } from "../../services/toast.service";
import { VcpService } from "../../services/vcp.service";
import { ToastType } from "src/app/entities/toast";
import { NotaService } from "src/app/services/nota.service";
import { NotaExplicativaService } from "src/app/nota-explicativa.service";
import * as Sentry from "@sentry/angular";
import { AppService } from "src/app/services/app-service";
import { PermissionService } from "src/app/services/permission-service";
import { PerfilUsuario } from "src/app/entities/perfil";
import { BasicModalComponent } from "src/app/shared/basic-modal/basic-modal.component";
import { BasicModalConfig } from "src/app/shared/basic-modal/basic-modal.config";
import { FormBuilder } from "@angular/forms";

type ConcluirVcp = "unidade-reprovada" | "finalizar-processo";

@Component({
  selector: "modal-etapa-vcp",
  templateUrl: "./modal-etapa-vcp.component.html",
  styleUrls: ["./modal-etapa-vcp.component.scss"],
})
export class ModalEtapaVcp {
  @Input() notaCertificacao: NotaCertificacao;
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() status: Status;
  @Output() concluirVCP = new EventEmitter();

  @ViewChild("modalConfirmProcesso")
  private modalConfirmProcesso: BasicModalComponent;
  @ViewChild("modalSelectOptionVcp")
  private modalSelectOptionVcp: BasicModalComponent;

  public modalConfirmProcessoConfig: BasicModalConfig = {
    modalTitle: ``,
    hideDenyButton: true,
    hideSaveButton: false,
    hideCloseButton: false,
    colorCloseButton: "secondary",
    saveButtonLabel: "Sim",
    options: {
      centered: false,
      size: "md",
    },
    onClose: async () => {
      this.clearVcpSelecionadoOption();
    },
    onSave: async () => {
      if (this.statusModalVcp === "unidade-reprovada") {
        this.ConcluirVcpUnidadeReprovada();
      } else if (this.statusModalVcp === "finalizar-processo") {
        this.ConcluirVcpFinalizarProcesso();
      }
    },
  };

  public modalSelectOptionVcpConfig: BasicModalConfig = {
    modalTitle: ``,
    hideDenyButton: true,
    hideSaveButton: false,
    hideCloseButton: false,
    disableSaveButton: true,
    colorCloseButton: "secondary",
    saveButtonLabel: "Confirmar",
    options: {
      centered: false,
      size: "md",
    },
    onClose: async () => {
      this.clearVcpSelecionadoOption();
    },
    onSave: async () => {
      this.onOpenModalSelectOptionVcp("unidade-reprovada");
    },
  };

  vcpList: any[] = [
    {
      id: null,
      descricao: "Selecione...",
    },
    // se 0 (false) é bci
    {
      id: 0,
      descricao: "VCP/BCI",
    },
    // se 1 (true) é abr
    {
      id: 1,
      descricao: "VCP/ABR",
    },
  ];

  textBodyModal: string = "";
  statusModalVcp: ConcluirVcp;

  usuarioLogado: any;
  notaAtingida: number;
  podeConcluirVcp = false;
  notaExplicativa: Boolean = false;
  podeAcessarVcp: Boolean = false;
  iconInfo = faInfoCircle;
  etapaAtual: any;

  containerNota: ContainerNotaVcpResponse;
  loadingNotaVcp = false;

  showSpanProcessSelectVcp = false;

  vcpSafraVigenteEmAndamento = false;

  podeSolicitarRetorno: boolean = false;

  podeReenviarEmail = false;
  podeVerNotaExplicativa = false;

  vcpForm = this.fb.group({
    vcpSelecionado: [null],
  });

  mapaStatusSolicitacaoRetorno: EtapaABR[] = [
    EtapaABR.VCP_CONCLUIDA,
    EtapaABR.VCP_REPROVADA,
    EtapaABR.CERTIFICACAO_EM_EMISSAO,
  ];
  podeVerOutrasOpcoes: boolean;

  constructor(
    private vcpService: VcpService,
    private service: Services,
    private appService: AppService,
    private toastService: ToastService,
    private spinnerService: SpinnerService,
    private modalService: NgbModal,
    private notaService: NotaService,
    private permissionService: PermissionService,
    private notaExplicativaService: NotaExplicativaService,
    private fb: FormBuilder
  ) {
    this.containerNota = this.notaService.iniciarContainerNotaEtapaVcp();
  }

  private _podeSolicitarRetorno() {
    this.podeSolicitarRetorno = this.appService.safraSelecionadaEhVigente();
    if (this.podeSolicitarRetorno == true) {
      let safraEscolhida = JSON.parse(
        window.localStorage.getItem("safraEscolhida")
      );
      this.service
        .get(
          `UpCertificacao/listastatus/${this.unidadeProdutiva.id}/${safraEscolhida.id}`
        )
        .subscribe((res) => {
          let resfilter = res.filter((x) => x.id != 27);
          let lastItem = resfilter.pop();
          this.etapaAtual = lastItem;
          this.podeSolicitarRetorno = this.mapaStatusSolicitacaoRetorno.some(
            (etapa) => this.etapaAtual.descricao === etapa
          );
        });
    }
  }

  ngOnInit(): void {
    this.loading();
    this.podeConcluirVcp = this.status.etapa === EtapaABR.VCP_CONCLUIDA;
    this.disableButtonModalSelectVcp();
  }

  disableButtonModalSelectVcp() {
    this.vcpForm?.get("vcpSelecionado").setValue(null);
    this.vcpForm?.get("vcpSelecionado").valueChanges.subscribe((x) => {
      if (!!x) {
        this.modalSelectOptionVcpConfig.disableSaveButton = false;
      } else {
        this.modalSelectOptionVcpConfig.disableSaveButton = true;
      }
    });
  }

  clearVcpSelecionadoOption() {
    const idSelecioando = this.vcpForm.get("vcpSelecionado").value;
    if (idSelecioando) {
      this.vcpForm.get("vcpSelecionado").setValue(null);
    }
  }

  openModalSelectVcp() {
    this.modalSelectOptionVcp.open();
  }

  checkIfUserCanConcludeReprovedVcp() {
    if (this.isEscolhidoFoiBci) {
      if (this.vcpBciReprovado) {
        this.openModalSelectVcp();
      }
    } else if (this.isEscolhidoFoiAbr) {
      if (this.vcpAbrReprovado) {
        // valor abr
        this.onOpenModalSelectOptionVcp("unidade-reprovada");
      }
    }
  }

  loading() {
    this.usuarioLogado = JSON.parse(window.localStorage.getItem("usuario"));
    this.BuscarNotaNecessaria();
    this.BuscarNotaObtida();
    this._podeSolicitarRetorno();

    this.vcpSafraVigenteEmAndamento =
      this.status.etapa === EtapaABR.VCP_EM_ANDAMENTO_INCOMPLETA ||
      this.status.etapa === EtapaABR.VCP_EM_ANDAMENTO_COMPLETA_NAO_CONCLUIDA;

    this.containerNota = this.notaService.iniciarContainerNotaEtapaVcp();

    this._carregarStatusNotaVCP();
    this.notaExplicativaService
      .verificaNotaExplicativa(this.unidadeProdutiva)
      .subscribe((res) => (this.notaExplicativa = res));

    this._podeReenviarEmail();

    this.podeAcessarVcp = this.status.etapa !== EtapaABR.NAO_INICIADO;
    let perfil = this.permissionService.getPerfisValidos();
    this.podeVerOutrasOpcoes = !perfil.some(
      (x) => x == PerfilUsuario.CERTIFICADORA_AUDITOR
    );
  }

  get isEscolhidoFoiBci(): boolean {
    return this.containerNota?.notasVcpBci?.meta?.selecionado ? true : false;
  }

  get isEscolhidoFoiAbr(): boolean {
    return this.containerNota?.notasVcpAbr?.meta?.selecionado ? true : false;
  }

  get possuiNotasVcpAbr(): boolean {
    return this.containerNota?.notasVcpAbr ? true : false;
  }

  get vcpAbrReprovado(): boolean {
    const abrReprovado =
      this.containerNota?.notasVcpAbr?.meta?.aprovado === false;
    return this.possuiNotasVcpAbr && abrReprovado;
  }

  get vcpAbrAprovado(): boolean {
    const abrAprovado =
      this.containerNota?.notasVcpAbr?.meta?.aprovado === true;
    return this.possuiNotasVcpAbr && abrAprovado;
  }

  get possuiNotasVcpBci(): boolean {
    return this.containerNota?.notasVcpBci ? true : false;
  }

  get vcpBciReprovado(): boolean {
    const bciReprovado =
      this.containerNota?.notasVcpBci?.meta?.aprovado === false;
    return this.possuiNotasVcpBci && bciReprovado;
  }

  get vcpBciAprovado(): boolean {
    const bciAprovado =
      this.containerNota?.notasVcpBci?.meta?.aprovado === true;
    return this.possuiNotasVcpBci && bciAprovado;
  }

  preencherVcp() {
    if (!this.podeAcessarVcp) return;
    this.spinnerService.show();

    var windowReference = window.open();
    this.vcpService.buscarFormulario(this.unidadeProdutiva).subscribe(
      (x) => {
        this.spinnerService.hide();
        const url = x?.url + x?.hash;
        windowReference.location.href = url;
      },
      (err) => {
        this.spinnerService.hide();
        Sentry.captureException(
          "Erro ao acessar o formulário, verificar se URL do formulário está correto."
        );
        this.toastService.showToast(
          ToastType.DANGER,
          "Error",
          "Erro ao acessar o formulário, favor entrar em contato com a ABRAPA."
        );
      }
    );
  }

  titleReprovedBciEscolhidoValue(): string {
    if (this.valueProcessoSelecionado() == 1) {
      if (this.vcpAbrAprovado)
        return "Confirmar o encerramento da etapa VCP/BCI com resultado de reprovação e com o resultado VCP/ABR de aprovado?";
      else
        return "Confirmar o encerramento da etapa VCP/BCI com resultado de reprovação e com o resultado VCP/ABR de reprovado?";
    } else {
      if (this.vcpAbrAprovado)
        return "Confirmar o encerramento da etapa VCP/BCI com resultado de reprovação?";
      else
        return "Confirmar o encerramento da etapa VCP/BCI com resultado de reprovação e com o resultado VCP/ABR de reprovado?";
    }
  }

  onOpenModalSelectOptionVcp(statusModalVcp: ConcluirVcp) {
    this.statusModalVcp = statusModalVcp;

    if (statusModalVcp === "unidade-reprovada") {
      if (this.isEscolhidoFoiBci) {
        this.modalConfirmProcessoConfig.modalTitle =
          this.titleReprovedBciEscolhidoValue();
        this.textBodyModal = `Uma vez realizada esta ação, não será mais permitido realizar alterações no formulário de VCP${
          this.valueProcessoSelecionado() == 0
            ? " e não será permitido também que a UP realize o processo de Certificação e se for o caso do Licenciamento!"
            : "!"
        }`;
      } else {
        this.modalConfirmProcessoConfig.modalTitle =
          "Confirmar o encerramento da etapa VCP/ABR com resultado de reprovação?";
        this.textBodyModal =
          "Uma vez realizada esta ação, não será mais permitido realizar alterações no formulário de VCP!";
      }
    } else if (statusModalVcp === "finalizar-processo") {
      if (this.isEscolhidoFoiBci) {
        this.modalConfirmProcessoConfig.modalTitle = `Confirmar o encerramento da etapa VCP/BCI, liberando a UP para a próxima etapa de emissão de Certificado ABR e se for o caso do Licenciamento BCI?`;
        this.textBodyModal =
          "Uma vez realizada esta ação, não será mais permitido realizar alterações no formulário de VCP!";
      } else {
        this.modalConfirmProcessoConfig.modalTitle = `Confirmar o encerramento da etapa VCP/ABR?`;
        this.textBodyModal =
          "Uma vez realizada esta ação, não será mais permitido realizar alterações no formulário de VCP!";
      }
    }
    this.modalConfirmProcesso.open();
  }

  valueProcessoSelecionado(): number {
    if (this.isEscolhidoFoiBci) {
      const idSelecioando = this.vcpForm.get("vcpSelecionado").value;
      return Number(idSelecioando);
    } else {
      return 1;
    }
  }

  messageToastReproved() {
    if (this.isEscolhidoFoiBci) {
      const bciReprovadoMsg = "VCP/BCI - Reprovado com sucesso";

      let abrMsg = "";

      if (this.valueProcessoSelecionado() == 1) {
        if (this.vcpAbrAprovado) {
          abrMsg = "VCP/ABR - Aprovado com sucesso";
        } else {
          abrMsg = "VCP/ABR - Reprovado com sucesso";
        }
      } else {
        if (this.vcpAbrAprovado) {
          abrMsg = "";
        } else {
          abrMsg = "VCP/ABR - Reprovado com sucesso";
        }
      }

      this.toastService.showToast(
        ToastType.SUCCESS,
        "Sucesso",
        bciReprovadoMsg + "\n" + abrMsg
      );
    } else {
      this.toastService.showToast(
        ToastType.SUCCESS,
        "Sucesso",
        "VCP/ABR reprovado com sucesso!"
      );
    }
  }

  ConcluirVcpUnidadeReprovada() {
    if (this.podeReprovarVcp) return;

    const idSelecioando = this.valueProcessoSelecionado();
    this.spinnerService.show();
    this.vcpService
      .concluirVcpUnidadeProdutivaReprovada(
        this.unidadeProdutiva,
        idSelecioando
      )
      .subscribe({
        next: (x) => {
          if (x.code === 201) {
            this.messageToastReproved();
            this.loading();
            this.concluiuVcp();
          } else {
            this.toastService.showToast(ToastType.DANGER, "Error", x.message);
          }
          this._carregarStatusNotaVCP();
        },
        complete: () => {
          this.spinnerService.hide();
        },
      });
  }

  ConcluirVcpFinalizarProcesso() {
    if (this.podeFinalizarVcp) return;

    this.spinnerService.show();
    this.vcpService
      .concluirVcpFinalizarProcesso(this.unidadeProdutiva.id)

      .subscribe({
        next: (x) => {
          this._carregarStatusNotaVCP();
          if (x.code === 201) {
            this.toastService.showToast(
              ToastType.SUCCESS,
              "Sucesso",
              "VCP - Aprovada com sucesso!"
            );
            this.loading();
            this.concluiuVcp();
          } else {
            this.toastService.showToast(ToastType.DANGER, "Error", x.message);
          }
        },
        complete: () => {
          this.spinnerService.hide();
        },
      });
  }

  BuscarNotaNecessaria() {
    this.vcpService
      .getNotaNecessariaSafra(this.unidadeProdutiva.codigoSafra)
      .subscribe((x) => {
        this.notaCertificacao = x;
      });
  }

  BuscarNotaObtida() {
    this.vcpService.getNotaObtida(this.unidadeProdutiva).subscribe((x) => {
      this.notaAtingida = x;
    });
  }

  private _carregarStatusNotaVCP() {
    this.loadingNotaVcp = true;
    this.vcpService
      .getNotasSafraSelecionadaEtapaVcp(this.unidadeProdutiva)
      .pipe(finalize(() => (this.loadingNotaVcp = false)))
      .subscribe(
        (res) => {
          this.containerNota = res;
          this.checkToShowSpan(
            this.containerNota?.notasVcpBci?.meta?.selecionado
          );
        },
        (err) => {
          console.log({ err });
        }
      );
  }

  checkToShowSpan(selecionado: boolean) {
    if (selecionado == null || selecionado == undefined) {
      this.showSpanProcessSelectVcp = false;
    } else {
      this.showSpanProcessSelectVcp = true;
    }
  }

  open(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }

  reenviarEmail() {
    this.spinnerService.show();
    this.vcpService
      .reenviarEmail(this.unidadeProdutiva)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe((res) => {
        this.toastService.showToast(ToastType.SUCCESS, "Success", res.message);
      });
  }

  preencherNotaExplicativa() {
    console.log("TODO: Redirecionar para preencher nota explicativa");

    // TODO!!
    // this.service
    //   .post("EtapaVcp/BuscarFormulario", certificacao)
    //   .subscribe((x) => {
    //     window.open(x?.url + x?.hash, "_blank");
    //   });
  }

  concluiuVcp() {
    this.concluirVCP.emit();
    this.modalConfirmProcesso.close();
    this.modalSelectOptionVcp.close();
  }

  private _podeReenviarEmail() {
    this.vcpService
      .getStatusAtualSafraSelecionada(this.unidadeProdutiva)
      .subscribe((statusAtual) => {
        this.podeReenviarEmail =
          statusAtual.etapa === EtapaABR.VCP_CONCLUIDA ||
          statusAtual.etapa === EtapaABR.VCP_EM_ANDAMENTO;
        this.podeVerNotaExplicativa =
          statusAtual.etapa === EtapaABR.VCP_EM_ANDAMENTO ||
          statusAtual.etapa === EtapaABR.VCP_CONCLUIDA ||
          statusAtual.etapa === EtapaABR.VCP_APROVADA ||
          statusAtual.etapa === EtapaABR.CERTIFICACAO_EM_EMISSAO ||
          statusAtual.etapa === EtapaABR.CERTIFICADA_ABR_NAO_OPTANTE_BCI ||
          statusAtual.etapa === EtapaABR.CERTIFICADA_ABR_EM_ANDAMENTO_BCI ||
          statusAtual.etapa === EtapaABR.CERTIFICADA_ABR_LICENCIADO_BCI ||
          statusAtual.etapa === EtapaABR.CERTIFICADA_ABR_REPROVADO_BCI ||
          statusAtual.etapa === EtapaABR.CERTIFICADA_ABR_CANCELADA_BCI;
      });
  }

  get podeFinalizarVcp(): boolean {
    if (this.isEscolhidoFoiBci) {
      return !(this.vcpBciAprovado && this.podeConcluirVcp);
    } else if (this.isEscolhidoFoiAbr) {
      return !(this.vcpAbrAprovado && this.podeConcluirVcp);
    } else return !this.podeConcluirVcp;
  }

  get podeReprovarVcp(): boolean {
    if (this.isEscolhidoFoiBci) {
      return !(this.vcpBciReprovado && this.podeConcluirVcp);
    } else if (this.isEscolhidoFoiAbr) {
      return !(this.vcpAbrReprovado && this.podeConcluirVcp);
    } else return !this.podeConcluirVcp;
  }
}
