import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiResponse } from "../entities/api-response";
import {
  ContainerNota,
  ContainerNotaVcpResponse,
  ContainerNotaVdpResponse,
  MetaNota,
  Nota,
  NotaVcpResponse,
  NotaVdpResponse,
  StatusNota,
} from "../entities/NotaCertificacao";

@Injectable({
  providedIn: "root",
})
export class NotaService {
  constructor() {}

  iniciarContainerNota(): ContainerNota {
    return {
      data: [],
      meta: {
        aprovado: false,
        notaMinimaPerguntas: 100,
        perguntasReprovadasCMP: "",
      },
      temNota: false,
      statusNota: StatusNota.NAO_INICIADO,
    };
  }

  iniciarContainerNotaEtapaVcp(): ContainerNotaVcpResponse {
    return {
      notasVcpAbr: {
        data: [],
        meta: {
          aprovado: false,
          notaMinimaPerguntas: 100,
          perguntasReprovadasCMP: "",
        },
      },
      notasVcpBci: {
        data: [],
        meta: {
          aprovado: false,
          notaMinimaPerguntas: 100,
          perguntasReprovadasCMP: "",
        },
      },
      temNotaAbr: false,
      temNotaBci: false,
      statusNotaAbr: StatusNota.NAO_INICIADO,
      statusNotaBci: StatusNota.NAO_INICIADO,
    };
  }

  iniciarContainerNotaEtapaVdp(): ContainerNotaVdpResponse {
    return {
      notasVdpAbr: {
        data: [],
        meta: {
          aprovado: false,
          notaMinimaPerguntas: 100,
          perguntasReprovadasCMP: "",
        },
      },
      notasVdpBci: {
        data: [],
        meta: {
          aprovado: false,
          notaMinimaPerguntas: 100,
          perguntasReprovadasCMP: "",
        },
      },
      temNotaAbr: false,
      temNotaBci: false,
      statusNotaAbr: StatusNota.NAO_INICIADO,
      statusNotaBci: StatusNota.NAO_INICIADO,
    };
  }

  criarContainerNota(response: ApiResponse): ContainerNota {
    const temNota = response.data && response.meta;
    return {
      data: response.data,
      meta: response.meta,
      temNota,
      statusNota: this.criarStatusNota(temNota, response.meta?.aprovado),
    };
  }

  criarContainerNotaVdp(response: any): ContainerNotaVdpResponse {
    const temNotaAbr =
      response?.notasVdpAbr?.data && response.notasVdpAbr?.meta;
    const temNotaBci =
      response?.notasVdpBci?.data && response.notasVdpBci?.meta;

    return {
      notasVdpAbr: {
        data: response?.notasVdpAbr?.data,
        meta: response?.notasVdpAbr?.meta,
      },
      notasVdpBci: {
        data: response.notasVdpBci?.data,
        meta: response.notasVdpBci?.meta,
      },
      temNotaAbr,
      temNotaBci,
      statusNotaAbr: this.criarStatusNota(
        temNotaAbr,
        response.notasVdpAbr?.meta?.aprovado
      ),
      statusNotaBci: this.criarStatusNota(
        temNotaBci,
        response.notasVdpBci?.meta?.aprovado
      ),
    };
  }

  criarContainerNotaVcp(response: any): ContainerNotaVcpResponse {
    const temNotaAbr =
      response?.notasVdpAbr?.data && response.notasVdpAbr?.meta;
    const temNotaBci =
      response?.notasVdpBci?.data && response.notasVdpBci?.meta;

    return {
      notasVcpAbr: {
        data: response?.notasVdpAbr?.data,
        meta: response?.notasVdpAbr?.meta,
      },
      notasVcpBci: {
        data: response.notasVdpBci?.data,
        meta: response.notasVdpBci?.meta,
      },
      temNotaAbr,
      temNotaBci,
      statusNotaAbr: this.criarStatusNota(
        temNotaAbr,
        response.notasVdpAbr?.meta?.aprovado
      ),
      statusNotaBci: this.criarStatusNota(
        temNotaBci,
        response.notasVdpAbr?.meta?.aprovado
      ),
    };
  }

  criarStatusNota(temNota: boolean, aprovado: boolean) {
    if (!temNota) {
      return StatusNota.NAO_INICIADO;
    }
    return aprovado ? StatusNota.APROVADO : StatusNota.REPROVADO;
  }

  formatarNotas(
    res: Observable<ApiResponse<Nota, MetaNota>>
  ): Observable<ContainerNota> {
    return res.pipe(map((res) => this.criarContainerNota(res)));
  }

  formatarNotasVDP(
    res: Observable<ApiResponse<NotaVdpResponse>>
  ): Observable<ContainerNotaVdpResponse> {
    return res.pipe(map((res) => this.criarContainerNotaVdp(res)));
  }

  formatarNotasVCP(
    res: Observable<ApiResponse<NotaVcpResponse>>
  ): Observable<any> {
    return res.pipe(map((res) => this.criarContainerNotaVcp(res)));
  }
}
