<a
  style="text-decoration: none; color: #6300bf; cursor: pointer"
  (click)="openModal(content)"
>
  Gerenciar
</a>

<ng-template #content let-modal>
  <div class="modal-body">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-6">
          <label class="text-muted">Etapa atual</label><br />
          <app-etapa-abr-dashboard-td
            [etapa]="dadosParecerSolicitacaoRetorno?.statusCertificacaoAtual"
          ></app-etapa-abr-dashboard-td>
        </div>
        <div class="col-lg-6">
          <label class="text-muted">Etapa desejada</label><br />
          <app-etapa-abr-dashboard-td
            [etapa]="dadosParecerSolicitacaoRetorno?.statusCertificacaoDesejado"
          ></app-etapa-abr-dashboard-td>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6">
              <label class="text-muted">Solicitante</label><br />
              <label
                ><b>{{
                  dadosParecerSolicitacaoRetorno?.usuarioSolicitacao | uppercase
                }}</b></label
              >
            </div>
            <div class="col-lg-6">
              <label class="text-muted">Data/Hora Solicatação</label><br />
              <label>{{
                dadosParecerSolicitacaoRetorno?.dataSolicitacao
                  | date : "dd/MM/yyyy HH:mm:ss"
              }}</label>
            </div>
            <div class="col-lg-6">
              <label class="text-muted">Resp. Parecer</label><br />
              <label
                ><b>{{
                  dadosParecerSolicitacaoRetorno?.usuarioParecer | uppercase
                }}</b></label
              >
            </div>
            <div class="col-lg-6">
              <label class="text-muted">Data/Hora Solicitação</label><br />
              <label>{{
                dadosParecerSolicitacaoRetorno?.dataParecer
                  | date : "dd/MM/yyyy HH:mm:ss"
              }}</label>
            </div>
            <div class="col-lg-12">
              <label class="text-muted">Motivo da Solicitação</label><br />
              <label
                ><b>{{
                  dadosParecerSolicitacaoRetorno?.motivo | uppercase
                }}</b></label
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="row">
            <div class="col-lg-12">
              <label class="text-muted">Status</label><br />
              <label
                ><span
                  [ngClass]="
                    buscaCorEtapa(dadosParecerSolicitacaoRetorno?.status)
                  "
                ></span>
                <b>{{
                  dadosParecerSolicitacaoRetorno?.status.toLowerCase() ===
                  "em_analise"
                    ? "Em análise"
                    : dadosParecerSolicitacaoRetorno?.status
                }}</b></label
              >
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light btn-sm"
            (click)="modal.close()"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm"
            *ngIf="habilitarBtn"
            (click)="openModalGerenciarConfirm(false)"
          >
            Reprovar solicitação
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            *ngIf="habilitarBtn"
            (click)="openModalGerenciarConfirm(true)"
          >
            Aprovar solicitação
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<basic-modal
  #modalGerenciarRetornoConfirm
  [modalConfig]="modalGerenciarRetornoConfirmConfig"
>
  <div>
    <p>{{ textBodyModal }}</p>
  </div>
</basic-modal>
