import { Component, EventEmitter, Input, Output } from "@angular/core";

import { UnidadeProdutiva } from "../../../entities/unidade-produtiva";
import { VdpService } from "../../../services/vdp.service";

@Component({
  selector: "modal-etapa-vdp-nota",
  templateUrl: "./modal-etapa-vdp-nota.component.html",
  styleUrls: ["./modal-etapa-vdp-nota.component.scss"],
})
export class ModalEtapaVdpNotaComponent {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() containerNota: any;
  @Input() modal: any;

  @Output() statusResposta = new EventEmitter();

  loadingNotas = true;
  modoSelecionado: string = "resumo-bci";

  constructor(public vdpService: VdpService) {}

  ngOnInit(): void {}

  selecionarModo(modo: string) {
    this.modoSelecionado = modo;
  }

  falhaNoRetorno(status: boolean) {
    this.statusResposta.emit(status);
  }

  onClose() {
    this.selecionarModo("resumo-bci");
    this.modal.close();
  }
}
