import { UpCertificacaoService } from "src/app/services/up-certificacao.service";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { finalize } from "rxjs/operators";
import { Status } from "../../entities/status";
import { UnidadeProdutiva } from "../../entities/unidade-produtiva";
import { FinalizandoCertificacaoService } from "src/app/services/finalizando-certificacao.service";
import { Safra } from "src/app/entities/safra";
import { ToastType } from "src/app/entities/toast";
import { ToastService } from "./../../services/toast.service";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { EtapaABR } from "src/app/entities/etapas";
import { BasicModalComponent } from "src/app/shared/basic-modal/basic-modal.component";
import { BasicModalConfig } from "src/app/shared/basic-modal/basic-modal.config";

type EtapaBci = "aprova" | "reprova" | "cancela" | "confirmaRetorno";
@Component({
  selector: "app-modal-etapa-licenca-bci",
  templateUrl: "./modal-etapa-licenca-bci.component.html",
  styleUrls: ["./modal-etapa-licenca-bci.component.scss"],
})
export class ModalEtapaLicencaBciComponent implements OnInit {
  @Input() unidadeProdutiva: UnidadeProdutiva;

  @ViewChild("modalEtapaLicencaBci")
  private modalEtapaLicencaBci: BasicModalComponent;

  public modalEtapaLicencaBciConfig: BasicModalConfig = {
    modalTitle: ``,
    hideDenyButton: true,
    hideSaveButton: false,
    hideCloseButton: false,
    colorCloseButton: "secondary",
    saveButtonLabel: "Sim",
    options: {
      centered: false,
      size: "md",
    },
    onClose: async () => {
      return;
    },
    onSave: async () => {
      if (this.statusEtapaBci === "aprova") {
        this.aprovaBCI();
      } else if (this.statusEtapaBci === "reprova") {
        this.reprovaBCI();
      } else if (this.statusEtapaBci === "cancela") {
        this.cancelaBCI();
      } else if (this.statusEtapaBci === "confirmaRetorno") {
        this.confirmarRetornoBci();
      }
    },
  };

  textBodyModal: string = "";
  statusEtapaBci: EtapaBci;

  icon = faExclamationTriangle;
  status: Status;

  safra: Safra;

  permissaoVerBotao: any;

  temArquivoLicencaBCI = false;

  temNumeroLicencaBCI = false;

  numeroCertificadoBci: String;

  loadingStatusVdp = false;

  podeAcessarLicencaBCI = false;

  clicked = false;

  isModalOpen = false;

  showEditarLicenca = false;

  editandoLicencaBCI = false;

  editarLicencaRetorno = false;

  constructor(
    private upCertificacaoService: UpCertificacaoService,
    private finalizandoCertificacaoService: FinalizandoCertificacaoService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this._carregarDado();
  }

  _carregarDado() {
    this.safra = JSON.parse(window.localStorage.getItem("safraEscolhida"));

    this._carregarStatusVDP();
    this.getStatusBci();
  }

  private _carregarStatusVDP() {
    this.loadingStatusVdp = true;
    this.upCertificacaoService
      .getStatusCertificacao(this.unidadeProdutiva)
      .pipe(finalize(() => (this.loadingStatusVdp = false)))
      .subscribe((status) => {
        this.status = status;
        this.permissaoVerBotao = this.verificaPermissaoBotao;
      });

    this.verificaArquivoLicencaBCI(this.unidadeProdutiva);
  }

  downloadLicencaBCI() {
    this.upCertificacaoService
      .downloadLicencaBci(this.unidadeProdutiva)
      .subscribe((res) => {
        const data = "data:application/pdf;base64," + res.conteudo;
        const link = document.createElement("a");
        const fileName = res.nomeArquivo;
        link.href = data;
        link.download = fileName;
        link.click();
      });
  }

  verificaArquivoLicencaBCI(unidadeProdutiva: UnidadeProdutiva) {
    if (this.temNumeroLicencaBCI) {
      this.upCertificacaoService
        .verificaDownloadLicencaBci(unidadeProdutiva)
        .subscribe((res) => {
          if (res?.conteudo != null && res?.conteudo != "") {
            this.temArquivoLicencaBCI = true;
          }
        });
    }
  }

  onEmitirLicencaBci() {
    this.ngOnInit();
  }

  getStatusBci() {
    this.finalizandoCertificacaoService
      .getStatusBCI(this.unidadeProdutiva, this.safra)
      .subscribe((res) => {
        this.numeroCertificadoBci =
          res?.numeroCertificado !== null
            ? res?.numeroCertificado
            : "Não inserido";
        this.temNumeroLicencaBCI =
          res?.numeroCertificado !== null ? true : false;

        this.podeAcessarLicencaBCI =
          res.status.etapa.toUpperCase() ===
          EtapaABR.CERTIFICADA_ABR_EM_ANDAMENTO_BCI.toUpperCase();

        this.showEditarLicenca =
          res.status.etapa.toUpperCase() ===
          EtapaABR.CERTIFICADO_ABR_BCI_LICENCIADO.toUpperCase();
      });
  }

  get tooltipConfirmar(): string {
    if (this.unidadeProdutiva.etapaAbr.includes("Certificada ABR ")) {
      return "";
    }
    //return !this.verificarCertificacaoABR ? 'Você precisa definir o número do certificado para Concluir o processo.' : ''
  }

  onOpenModalEtapaLicensaBci(licencaBci: EtapaBci) {
    this.statusEtapaBci = licencaBci;

    if (licencaBci === "aprova") {
      this.modalEtapaLicencaBciConfig.modalTitle = `Confirmar a aprovação do licenciamento BCI?`;
      this.textBodyModal =
        "Você confirma que deseja Aprovar a Unidade Produtiva para o Licenciamento BCI?";
    } else if (licencaBci === "reprova") {
      this.modalEtapaLicencaBciConfig.modalTitle = `Confirmar a reprovação do licenciamento BCI?`;
      this.textBodyModal =
        "Você confirma que deseja Reprovar a Unidade Produtiva para o Licenciamento BCI?";
    } else if (licencaBci === "cancela") {
      this.modalEtapaLicencaBciConfig.modalTitle = `Confirmar o cancelamento do licenciamento BCI?`;
      this.textBodyModal =
        "Uma vez realizado o cancelamento da licença da Unidade Produtiva, a ação é irreversível durante a safra vigente.";
    } else if (licencaBci === "confirmaRetorno") {
      this.modalEtapaLicencaBciConfig.modalTitle = `Confirmar o retorno da licença BCI?`;
      this.textBodyModal =
        "Você confirma que deseja Retornar para a etapa anterior da Licença BCI?";
    }
    this.modalEtapaLicencaBci.open();
  }

  aprovaBCI() {
    if (!this.verificaPermissaoBotao) {
      this.finalizandoCertificacaoService
        .aprovaBCI(this.unidadeProdutiva)
        .subscribe((res) => {
          this.toastService.showToast(
            res ? ToastType.SUCCESS : ToastType.DANGER,
            res ? "Sucesso" : "Falha",
            "Aprovado liceniamento BCI"
          );
          this.ngOnInit();
        });
      this.clicked = true;
    }
  }

  reprovaBCI() {
    if (!this.verificaPermissaoBotao) {
      this.finalizandoCertificacaoService
        .reprovaBCI(this.unidadeProdutiva)
        .subscribe((res) => {
          this.toastService.showToast(
            res ? ToastType.SUCCESS : ToastType.DANGER,
            res ? "Sucesso" : "Falha",
            "Certificação reprovada BCI"
          );
        });
      this.clicked = true;
    }
  }

  cancelaBCI() {
    if (!this.verificaPermissaoBotaoCancelar) {
      this.finalizandoCertificacaoService
        .cancelaBCI(this.unidadeProdutiva)
        .subscribe((res) => {
          this.toastService.showToast(
            res ? ToastType.SUCCESS : ToastType.DANGER,
            res ? "Sucesso" : "Falha",
            "Licenciamento BCI Cancelado"
          );
        });
    }
  }

  get verificaPermissaoBotao(): boolean {
    const etapaPermite =
      this.unidadeProdutiva.etapaAbr ===
      EtapaABR.CERTIFICADA_ABR_EM_ANDAMENTO_BCI;
    return !etapaPermite;
  }

  get verificaPermissaoBotaoCancelar(): boolean {
    const etapaNaoPermite =
      this.unidadeProdutiva.etapaAbr ===
        EtapaABR.CERTIFICADA_ABR_NAO_OPTANTE_BCI ||
      this.unidadeProdutiva.etapaAbr ===
        EtapaABR.CERTIFICADA_ABR_EM_ANDAMENTO_BCI ||
      this.unidadeProdutiva.etapaAbr ===
        EtapaABR.CERTIFICADA_ABR_LICENCIADO_BCI ||
      this.unidadeProdutiva.etapaAbr === EtapaABR.CERTIFICADA_ABR_REPROVADO_BCI;
    return !etapaNaoPermite;
  }

  confirmarRetornoBci(): void {
    this.editarLicencaRetorno = true;
    this.showEditarLicenca = false;
    this.editandoLicencaBCI = true;
  }
}
