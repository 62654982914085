export class NotaCertificacao {
  id: number;
  descricaoSafra: string;
  safra: string;
  percentual: number;
}

export interface Nota {
  id: number;
  criterio: string;
  porcentagemPerguntas: number;
  aprovadoPerguntas: boolean;
  aprovadoPerguntasConformidade: boolean;
  quantidadeItensTotal: number;
  quantidadeItensCertificacao: number;
  quantidadeItensCMP: number;
  itensReprovados: string;
  itensNaoRespondidos: string;
}

export interface MetaNota {
  id?: number;
  aprovado: boolean;
  notaMinimaPerguntas: number;
  perguntasReprovadasCMP: string;
  selecionado?: boolean;
}

export interface NotaVcpResponse {
  notasVdpAbr: NotaPorEtapa;
  notasVdpBci: NotaPorEtapa;
}

export interface NotaVdpResponse {
  notasVdpAbr: NotaPorEtapa;
  notasVdpBci: NotaPorEtapa;
}

export enum StatusNota {
  APROVADO = "Aprovado",
  REPROVADO = "Reprovado",
  NAO_INICIADO = "Não iniciado",
}

export interface ContainerNota {
  data: Nota[];
  meta: MetaNota;
  temNota: boolean;
  statusNota: StatusNota;
}

interface NotaPorEtapa {
  data: Nota[];
  meta: MetaNota;
}

export interface ContainerNotaVcpResponse {
  notasVcpAbr: NotaPorEtapa;
  notasVcpBci: NotaPorEtapa;
  temNotaAbr: boolean;
  temNotaBci: boolean;
  statusNotaAbr: StatusNota;
  statusNotaBci: StatusNota;
}

export interface ContainerNotaVdpResponse {
  notasVdpAbr: NotaPorEtapa;
  notasVdpBci: NotaPorEtapa;
  temNotaAbr: boolean;
  temNotaBci: boolean;
  statusNotaAbr: StatusNota;
  statusNotaBci: StatusNota;
}
