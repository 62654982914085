<app-modal-container
  (changeStep)="onChangeStep($event)"
  [steps]="steps"
  (onUpdate)="_carregarModal()"
  [unidadeProdutiva]="unidadeProdutiva"
  [esconderAndamentoCertificacao]="esconderAndamentoCertificacao"
  (toggleModalStatus)="toggleModalStatus($event)"
  [loading]="loading"
>
  <ng-container *ngIf="modalAberto">
    <modal-convite
      #modalConvite
      *ngIf="activeStepLabel === 'Convite'"
      [unidadeProdutiva]="unidadeProdutiva"
      [safraId]="safraId"
    ></modal-convite>
    <modal-etapa-vdp
      #modalEtapaVDP
      *ngIf="activeStepLabel === 'VDP'"
      [unidadeProdutiva]="unidadeProdutiva"
      (concluirEtapa)="onConcluiuEtapa()"
    ></modal-etapa-vdp>
    <modal-aceite-convite
      #modalAceiteConvite
      *ngIf="convidado"
      [unidadeProdutiva]="unidadeProdutiva"
    ></modal-aceite-convite>
    <modal-etapa-pcnc
      #modalEtapaPCNC
      *ngIf="activeStepLabel === 'PCNC'"
      [unidadeProdutiva]="unidadeProdutiva"
      (concluirEtapa)="onConcluiuEtapa()"
    ></modal-etapa-pcnc>
  </ng-container>
</app-modal-container>
