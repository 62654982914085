import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { PerfilUsuario } from "../entities/perfil";

@Injectable({
  providedIn: "root",
})
export class PermissionService {
  [x: string]: any;
  carregado = false;
  permission = new Array();
  usuario = JSON.parse(localStorage.getItem("usuario"));

  getPermission() {
    if (!this.usuario?.role) {
      this.carregado = false;
      return this.permission;
    }

    if (this.carregado) {
      return this.permission;
    }

    for (const m in this.usuario?.role) {
      const app = this.usuario.app[m];
      if (app == 2) {
        this.permission.push(this.usuario.role[m]);
      }
    }

    this.carregado = true;

    return this.permission;
  }

  getPerfisValidos() {
    return this.getPermission()?.filter((role) => PerfilUsuario[role]);
  }
}
