<titulo-modal>VCP</titulo-modal>

<div *ngIf="!loading; else shimmer" class="mb-3 content-responsive">
  <app-status-dot [status]="containerNota.statusNotaBci"></app-status-dot>
  <button class="btn btn-sm btn-link" (click)="open(content)">Notas VCP</button>
</div>

<ng-template #content let-modal>
  <app-modal-etapa-vcp-nota
    [containerNota]="containerNota"
    [unidadeProdutiva]="unidadeProdutiva"
    [modal]="modal"
  >
  </app-modal-etapa-vcp-nota>
</ng-template>

<ng-template #shimmer>
  <div class="d-flex flex-column">
    <shimmer width="160px" height="17px" class="mb-1"></shimmer>
    <shimmer width="90px"></shimmer>
    <shimmer width="120px" height="20px"></shimmer>
  </div>
</ng-template>
