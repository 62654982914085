<ng-container *ngIf="!loading; else shimmer">
  <button
    *ngIf="
      temPerfil('ESTADUAL') ||
      temPerfil('CERTIFICADORA') ||
      temPerfil('RESPONSAVEL_ABR')
    "
    class="btn btn-sm btn-link pl-0"
    (click)="open(content)"
  >
    <fa-icon [icon]="icon" size="sm" class="mr-2"></fa-icon>
    Notas VCP
  </button>
</ng-container>

<ng-template #shimmer>
  <div *ngFor="let item of shimmers" class="d-flex flex-column mb-3">
    <shimmer width="80%" height="18px"></shimmer>
  </div>
</ng-template>

<ng-template #content let-modal>
  <app-modal-etapa-vcp-nota
    [containerNota]="containerNotaVcp"
    [unidadeProdutiva]="unidadeProdutiva"
    [modal]="modal"
  >
  </app-modal-etapa-vcp-nota>
</ng-template>
